import {Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';

type SearchBoxState = {
    wineType: string;
    country: string;
    priceRange: string
}

export type SearchBoxRequest = {
    wineType: string;
    country: string;
    priceRange: string
}
type SearchBoxProps = { callback: (request: SearchBoxRequest) => void }

export default class SearchBox extends React.Component<SearchBoxProps, SearchBoxState> {
    private readonly callback: (request: SearchBoxRequest) => void;

    constructor(props: SearchBoxProps) {
        super(props);
        this.callback = props.callback;
        this.state = {wineType: '', country: '', priceRange: ''};
        this.wineTypeChanged = this.wineTypeChanged.bind(this);
        this.countryChanged = this.countryChanged.bind(this);
        this.priceRangeChanged = this.priceRangeChanged.bind(this);
        this.search = this.search.bind(this);
    }

    render() {
        return (
            <Grid container spacing={2} sx={{p: 1}}>
                <Grid item xs={12} display={'flex'} justifyContent={'flex-start'}>
                    <h3>Søg vin</h3>
                </Grid>
                {/*<Grid item xs={2} display={"flex"} justifyContent={"flex-start"}><label>Type</label></Grid>*/}
                <Grid item xs={3} display={'flex'} justifyContent={'flex-start'}>
                    <FormControl fullWidth>
                        <InputLabel id="type-select-label">Type</InputLabel>
                        <Select
                            labelId="type-select-label"
                            id="type-select"
                            value={this.state.wineType}
                            label="Type"
                            onChange={this.wineTypeChanged}
                        >
                            <MenuItem value={'all'}>Vis alle</MenuItem>
                            <MenuItem value={'red'}>Rødvin</MenuItem>
                            <MenuItem value={'white'}>Hvidvin</MenuItem>
                            <MenuItem value={'rose'}>Rosé</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {/*<Grid item xs={2} display={"flex"} justifyContent={"flex-start"}><label>Land</label></Grid>*/}
                <Grid item xs={3} display={'flex'} justifyContent={'flex-start'}>
                    <FormControl fullWidth>
                        <InputLabel id="country-select-label">Land</InputLabel>
                        <Select
                            labelId="country-select-label"
                            id="country-select"
                            value={this.state.country}
                            label="Land"
                            onChange={this.countryChanged}
                        >
                            <MenuItem value={'all'}>Vis alle</MenuItem>
                            <MenuItem value={'es'}>Spanien</MenuItem>
                            <MenuItem value={'fr'}>Frankrig</MenuItem>
                            <MenuItem value={'us'}>USA</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {/*<Grid item xs={2} display={"flex"} justifyContent={"flex-start"}><label>Prisniveau</label></Grid>*/}
                <Grid item xs={3} display={'flex'} justifyContent={'flex-start'}>
                    <FormControl fullWidth>
                        <InputLabel id="price-range-select-label">Pris niveau</InputLabel>
                        <Select
                            labelId="price-range-select-label"
                            id="price-range-select"
                            value={this.state.priceRange}
                            label="Pris"
                            onChange={this.priceRangeChanged}>
                            <MenuItem value={'all'}>Vis alle</MenuItem>
                            <MenuItem value={'0-80'}>0 - 80 kr.</MenuItem>
                            <MenuItem value={'80-110'}>80 - 110 kr.</MenuItem>
                            <MenuItem value={'110-200'}>110 - 200</MenuItem>
                            <MenuItem value={'200-'}>200 - ?</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <Button size={'large'} variant="contained" endIcon={<SearchIcon/>}
                            onClick={this.search}>Søg</Button>
                </Grid>
            </Grid>
        );
    }

    private priceRangeChanged(event: SelectChangeEvent) {
        this.setState({priceRange: event.target.value});
    }

    private countryChanged(event: SelectChangeEvent) {
        this.setState({country: event.target.value});
    }

    private wineTypeChanged(event: SelectChangeEvent) {
        this.setState({wineType: event.target.value});
    }

    private search() {
        const request = {
            wineType: this.state.wineType,
            country: this.state.country,
            priceRange: this.state.priceRange
        };
        this.callback(request);
    }
}