import React from 'react';
import './App.css';
import ButtonAppBar from './Navigation';
import {Box, Button, Container, FormControl, Grid, InputLabel, MenuItem, Select} from '@mui/material';
import SearchBox, {SearchBoxRequest} from './SearchBox';
import SearchResultsTable from './SearchResultsTable';

type AppProps = {
}

type AppState = {
    searchRequest: SearchBoxRequest|null
}

export default class App extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        this.state = {searchRequest: null};
    }

    async updateSearchResults(request: SearchBoxRequest) {
        console.log('App.updateSearchResults() called');
        console.log(request);
        this.setState({searchRequest: request});
    }

    render() {
        console.log('App.render() called')
        return (
            <div className="App">
                <ButtonAppBar></ButtonAppBar>
                <header id={'search'} className="App-header">
                    <Container>
                        <Box display={"flex"} justifyContent={"flex-start"} sx={{borderRadius: 6, color: 'primary.main',p:3}} bgcolor="background.default">
                            <SearchBox callback={(request: SearchBoxRequest) => (this.updateSearchResults(request))}></SearchBox>
                        </Box>
                    </Container>
                </header>
                <SearchResultsTable searchRequest={this.state?.searchRequest}></SearchResultsTable>
            </div>
        );
    }
}
