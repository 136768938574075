import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {SearchBoxRequest} from './SearchBox';
import search from './SearchAPI';

type SearchResultsProps = { searchRequest: SearchBoxRequest | null }
type SearchResultsState = { searchRequest: SearchBoxRequest | null, searchResults: SearchResult[] | null }
type SearchResult = {
    wineId: number;
    wineName: string,
    countryCode: string,
    storeName: string,
    wineryName: string,
    lowestPrice: number
    vintage: number
}

export default class SearchResultsTable extends React.Component<SearchResultsProps, SearchResultsState> {
    constructor(props: SearchResultsProps) {
        super(props);
        this.state = {searchRequest: null, searchResults: null};
    }

    async componentDidUpdate(prevProps: Readonly<SearchResultsProps>) {
        const searchRequest = this.props.searchRequest;
        if (searchRequest !== prevProps.searchRequest) {
            if (searchRequest === null) {
                this.setState({searchResults: []});
                return;
            }
            this.setState({
                searchResults: await search(searchRequest.wineType, searchRequest.country, searchRequest.priceRange)
            });
        }
    }

    render() {
        console.log(this.state.searchRequest);
        /*
        if (this.state.searchRequest !== null) {
            this.setState({searchResults: [{name: 'Antico Ceppo', lowestPrice: 50}]})
        }*/

        if (this.state.searchResults === null) {
            return '';
        }

        /*return (
            <SearchResultCard></SearchResultCard>
        );*/

        return (
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Navn</TableCell>
                            <TableCell>Fabrikant</TableCell>
                            <TableCell>Årgang</TableCell>
                            <TableCell>Sælger</TableCell>
                            <TableCell align="right">Laveste pris</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.searchResults.map((row) => (
                            <TableRow
                                key={row.wineId}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell component="th" scope="row">
                                    {row.wineName}
                                </TableCell>
                                <TableCell>{row.wineryName}</TableCell>
                                <TableCell>{row.vintage}</TableCell>
                                <TableCell>{row.storeName}</TableCell>
                                <TableCell align="right">{row.lowestPrice}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}