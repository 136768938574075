import axios from 'axios';

type SearchResult = {
    wineId: number,
    wineName: string,
    countryCode: string,
    storeName: string,
    wineryName: string,
    lowestPrice: number,
    vintage: number,
};

export default async function search(wineType: string = 'red', country: string = 'all', priceRange: string = 'all'): Promise<SearchResult[]> {
    if (!wineType) {
        wineType = 'all';
    }
    if (!country) {
        country = 'all';
    }
    if (!priceRange) {
        priceRange = 'all';
    }
    console.log(`Search: ${wineType}, ${country}, ${priceRange}`);
    // return [{name: 'Test', lowestPrice: 100}];
    const response = await axios.get<SearchResult[]>(`https://zdz34jhqrb.execute-api.eu-central-1.amazonaws.com/prod/offers/${wineType}/${country}/${priceRange}`);
    return response.data;
}
