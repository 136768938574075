import {createTheme} from '@mui/material';

export default createTheme({
    palette: {
        primary: {
            main: '#810743',
        },
        secondary: {
            main: '#f50057',
        },
    },
    typography: {
        h1: {
            fontFamily: 'Oswald',
        },
        h2: {
            fontFamily: 'Oswald',
        },
        fontFamily: 'Oswald',
    },
});